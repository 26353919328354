import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useUserContext } from '@contexts/user-context';
import { PROFILE, REGISTER } from '@constants/routes';
import {
  AUTH_FLOW_NEWSLETTER_OPT_IN_STEP,
  AUTH_FLOW_LOGIN_STEP,
  AUTH_FLOW_REGISTER_STEP,
  AUTH_FLOW_REGISTER_VERIFY_STEP,
  AUTH_FLOW_PARENTAL_CONSENT_STEP,
  AUTH_FLOW_POTTERMORE_MIGRATE_STEP,
  AUTH_FLOW_POTTERMORE_REGISTER_STEP,
  AUTH_FLOW_POTTERMORE_VERIFY_STEP,
} from '@constants/auth';
import { useGlobalState } from '../../globalStates/AuthFlowState';

// TODO: update these lists if need be while wiring up underage and pottermore flows
const AUTHENTICATED_USER_ONLY_PAGES = [AUTH_FLOW_NEWSLETTER_OPT_IN_STEP];
const ANON_USER_ONLY_PAGES = [
  AUTH_FLOW_LOGIN_STEP,
  AUTH_FLOW_REGISTER_STEP,
  AUTH_FLOW_PARENTAL_CONSENT_STEP,
  AUTH_FLOW_POTTERMORE_MIGRATE_STEP,
  AUTH_FLOW_POTTERMORE_REGISTER_STEP,
  AUTH_FLOW_POTTERMORE_VERIFY_STEP,
];

const useAuthFlowRouter = (pageName) => {
  const router = useRouter();
  const [isRouting, setIsRouting] = useState(true);
  const [currentPageName, setCurrentPageName] = useState(null);
  const { isLoggedIn, loadingProfile, loading } = useUserContext();
  const [isNewAccountCreated] = useGlobalState('isNewAccountCreated');
  const [isUnverified] = useGlobalState('isUnverified');
  const [isUnderAge] = useGlobalState('isUnderAge');
  const [isMigration] = useGlobalState('isMigration');
  const underageSession = process.browser && isUnderAge;

  useEffect(() => {
    const redirectUser = (route) => {
      router.replace(route);
      setIsRouting(true);
    };

    // Wait for logged in state and profile to load before deciding where/if to route.
    // If neither are loading, and we haven't changed pages, then do nothing.
    if (loadingProfile || loading || pageName === currentPageName) {
      return;
    }

    // Failsafe to prevent underage users from hitting email opt-in
    if (pageName === AUTH_FLOW_NEWSLETTER_OPT_IN_STEP && underageSession) {
      redirectUser(PROFILE);
      return;
    }

    // Users who haven't just completed registration should be redirected from newsletter opt-in
    if (pageName === AUTH_FLOW_NEWSLETTER_OPT_IN_STEP && !isNewAccountCreated) {
      redirectUser(PROFILE);
      return;
    }

    // Users who aren't in the Pottermore verify flow should redirect to register
    if (pageName === AUTH_FLOW_POTTERMORE_VERIFY_STEP && !isUnverified) {
      redirectUser(REGISTER);
      return;
    }

    // Users who aren't in the verify flow should redirect to register
    if (pageName === AUTH_FLOW_REGISTER_VERIFY_STEP && !isUnverified) {
      redirectUser(REGISTER);
      return;
    }

    // Users who aren't in the migrate flow should redirect to register
    // The AUTH_FLOW_POTTERMORE_REGISTER_STEP check is done at the page component level.
    if (pageName === AUTH_FLOW_POTTERMORE_MIGRATE_STEP && !isMigration) {
      redirectUser(REGISTER);
      return;
    }

    // Logged in users should not be able to access these routes
    if (ANON_USER_ONLY_PAGES.includes(pageName) && isLoggedIn) {
      redirectUser(PROFILE);
      return;
    }

    // Anon users should not be able to access these routes
    if (AUTHENTICATED_USER_ONLY_PAGES.includes(pageName) && !isLoggedIn) {
      redirectUser(REGISTER);
      return;
    }

    setIsRouting(false);
    setCurrentPageName(pageName);
  }, [
    isLoggedIn,
    loadingProfile,
    loading,
    pageName,
    isUnverified,
    currentPageName,
    underageSession,
    isNewAccountCreated,
    router,
    isMigration,
  ]);

  return {
    isRouting,
  };
};

export default useAuthFlowRouter;
