/* eslint-disable no-unused-expressions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BasicInput from '../BasicInput';
import s from './TogglePasswordInput.module.scss';

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  iconHide: PropTypes.string,
  iconShow: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onVisibilityToggle: PropTypes.func,
  inputref: PropTypes.shape({ current: PropTypes.any }),
};

const TogglePassword = ({
  iconHide = 'password_hide',
  iconShow = 'password_show',
  disabled = false,
  iconColor = '',
  className = '',
  iconClassName = '',
  onVisibilityToggle = () => {},
  inputref = null,
  ...props
}) => {
  const basicInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (basicInputRef.current !== null) {
      const { length } = basicInputRef.current.value;
      basicInputRef.current.focus();

      // this forces the cursor to the end of the input (specifically for chrome as default is to the beginning)
      setTimeout(() => {
        basicInputRef?.current?.setSelectionRange(length, length);
      }, 0);
    }

    onVisibilityToggle(showPassword);
    if (!disabled) setShowPassword(!showPassword);
  };

  return (
    <BasicInput
      type={showPassword ? 'text' : 'password'}
      iconClassName={cx(s.icon, iconClassName)}
      iconWidth="16px"
      iconHeight="16px"
      {...props}
      icon={showPassword ? iconShow : iconHide}
      onIconClick={(e) => handleToggle(e)}
      piiData
      disabled={disabled}
      iconColor={iconColor}
      className={className}
      inputrefs={[basicInputRef, inputref]}
    />
  );
};

TogglePassword.propTypes = propTypes;
export default TogglePassword;
