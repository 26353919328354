import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  dateOfBirth: '',
  email: '',
  isMigration: false,
  password: false,
  isNewAccountCreated: false,
  isUnderAge: false,
  parentId: null,
  isUnverified: false,
};

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState(initialState);

export const setDateOfBirth = (dob) => {
  setGlobalState('dateOfBirth', dob);
};

export const setEmail = (email) => {
  setGlobalState('email', email);
};

export const setIsMigration = (isMigration) => {
  setGlobalState('isMigration', isMigration);
};

export const setIsUnverified = (isUnverified) => {
  setGlobalState('isUnverified', isUnverified);
};

export const setPassword = (password) => {
  setGlobalState('password', password);
};

export const setIsUnderAge = (isUnderAge) => {
  setGlobalState('isUnderAge', isUnderAge);
};

export const setParentId = (parentId) => {
  setGlobalState('parentId', parentId);
};

export const setIsNewAccountCreated = (isNewAccountCreated) => {
  setGlobalState('isNewAccountCreated', isNewAccountCreated);
};

export { useGlobalState, getGlobalState };
